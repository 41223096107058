@font-face {
  font-family: "fonticon";
  src: url("fonts/fonticon.eot?etlbq9");
  src: url("fonts/fonticon.eot?etlbq9#iefix") format("embedded-opentype"),
    url("fonts/fonticon.ttf?etlbq9") format("truetype"),
    url("fonts/fonticon.woff?etlbq9") format("woff"),
    url("fonts/fonticon.svg?etlbq9#fonticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "fonticon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 1.5em;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Illustration-7:before {
  content: "\e900";
  color: #e63946;
}
.icon-Illustration-9:before {
  content: "\e901";
  color: #e63946;
}
.icon-Illustration-10:before {
  content: "\e902";
  color: #e63946;
}
.icon-Illustration-11:before {
  content: "\e903";
  color: #e63946;
}
.icon-Illustration-12:before {
  content: "\e904";
  color: #e63946;
}
.icon-Illustration-13:before {
  content: "\e905";
  color: #e63946;
}
.icon-Illustration-14:before {
  content: "\e906";
  color: #e63946;
}
.icon-Illustration-15:before {
  content: "\e907";
  color: #e63946;
}
.icon-Illustration-18:before {
  content: "\e908";
  color: #e63946;
}
