.card-content {
  color: #171a1c;
  font: "Source Sans Pro";
  margin: 0;
  min-height: 100%;
  padding: 5vw 0;
}

.card {
  background-color: #1d1d28;
	 border-radius: 1rem;
	 color: #47474e;
	 padding: 2rem;
	 font-family: Abel;
   max-width: 440px;
   height: 240px;
   display: flex;
   flex-wrap: wrap;
   margin: auto;
}
.card > div {
  width: 100%;
}
 .card__header {
	 margin-bottom: 4rem;
}
 .card__title {
	 color: #fff;
	 float: left;
	 font-size: 1.5rem;
}
 .card__logo {
	 float: right;
}
 .card__number__input {
	 font-size: 2rem;
}
 .card__expiration {
	 float: left;
}
 .card__ccv {
	 float: right;
}
 .card__ccv__input {
	 width: 1.75rem;
}
 .card .ui-selectmenu-button {
	 float: left;
	 margin-right: 2rem;
}

.CardField .InputElement {
  color: white !important;
}
 
