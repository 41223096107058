
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#container_spinner > div > img {
  display: none;
}

#imagespinner {
  width: 100px;
  position: absolute;
  right: 10px;
  top: 80px;
  z-index: 20;
  transform: rotate(-30deg);
}