.rc-time-picker-panel {
  z-index: 9999 !important;
}

.rc-time-picker {
  width: 100%;
}

.rc-time-picker input {
  height: 43px !important;
  font-size: 16px !important;
}